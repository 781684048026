import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

// import "./assets/styles/fireworks.css";
import "./assets/styles/snowfall.css";

class Result extends Component {
  // changeColor(hexCode) {
  //   var elems = document.getElementsByClassName("result-color");
  //   for (let i = 0; i < elems.length; i++) {
  //     elems[i].style.color = hexCode;
  //   }
  // }

  // componentDidMount() {
  //   const { user } = this.props.location.state;
  //   this.changeColor(user.HexCode);
  // }

  render() {
    if (!this.props.location.state) {
      return <Redirect to="/" />;
    }

    const { user } = this.props.location.state;
    if (!user) {
      return <Redirect to="/" />;
    }

    var snowflakes = [];
    for (var i = 0; i < 100; i++) {
      snowflakes.push(<div className="snowflake" key={i} />);
    }

    return (
      <div className="pyro">
        {snowflakes}
        <div className="before"></div>
        <div className="after"></div>
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container has-text-centered">
              <p className="title is-1 has-text-primary">
                {user.FirstName}
                <span className="has-text-white">,</span>
              </p>
              <p className="subtitle is-2 has-text-white">
                Tu dois offrir un cadeau à{" "}
                <strong className="has-text-primary">{user.GiftedUser}</strong>{" "}
                !
              </p>
              <p className="subtitle has-text-white">
                À toi de trouver le cadeau idéal qui lui fera plaisir !
              </p>
              <p className="subtitle has-text-white">
                Le budget pour le cadeau est d'environ 15€
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Result);
