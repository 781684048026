import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./Authentication.css";

class Authentication extends Component {
  state = { errorMessage: "" };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    let progressbar = document.getElementById("progress-bar");
    progressbar.classList.remove("is-hidden")

    event.preventDefault();

    fetch("https://random-draw.azurewebsites.net/users/verify?password=" + this.state.accessCode)
      .then((res) => res.json())
      .then((data) => {
        this.props.history.push({
          pathname: "/result",
          state: { user: data },
        });
      })
      .catch((err) => {
        this.setState({ errorMessage: "Mauvais code d'accès" });
      })
      .finally(() => {
        progressbar.classList.add("is-hidden");
      });
  }

  render() {
    return (
      <form id="formAccess" onSubmit={this.handleSubmit.bind(this)}>
        <div className="field has-addons">
          <p className="control is-expanded has-icons-left">
            <input
              className="input"
              type="text"
              placeholder="Code d'accès"
              name="accessCode"
              onChange={this.handleInputChange.bind(this)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-key"></i>
            </span>
          </p>
          <div className="control">
            <button className="button is-primary">Valider</button>
          </div>
        </div>
        {this.state.errorMessage.length > 0 && (
          <p className="has-text-danger">{this.state.errorMessage}</p>
        )}
        <progress id="progress-bar" className="progress is-small is-primary is-hidden" max="100"></progress>
      </form>
    );
  }
}

export default withRouter(Authentication);
