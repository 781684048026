import React, { Component } from "react";
import { withRouter } from "react-router";

import Authentication from "./Authentication";

import "./Home.css";

class Home extends Component {
  render() {
    return (
      <div>
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 has-text-white has-text-centered">
                Noël des chipettes !
              </h1>
              <Authentication />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Home);
